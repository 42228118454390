@import '../../../styles/customMediaQueries.css';

.moreAbout {
  background: var(--marketplaceColor);
  color: var(--colorWhite);
  & .contentWidth {
    &:first-child {
      padding-bottom: 0;
    }
    &:last-child {
      padding-top: 0;
    }

    .sectionHead {
      display: flex;
      width: 100%;
      justify-content: center;
      margin-bottom: 48px;
      align-items: start;
      flex-direction: column;

      & .leftSec {
        text-align: center;

        & h2 {
          font-weight: 600;
          letter-spacing: 0em;
          color: var(--colorWhite);
          margin: 0px 0 5px 0;
          padding: 0;
          font-size: 20px;
          line-height: 130%;
          @media (--viewportMedium) {
            font-size: 24px;
          }
          @media (--viewportLarge) {
            font-size: 26px;
          }
        }
        & p {
          font-size: 16px;
          font-weight: 400;
          line-height: 150%;
          letter-spacing: 0em;
          color: var(--colorWhite);
          margin: 0px;
          padding: 0;
        }
      }
    }
  }
}

.moreHealers {
  & h1 {
    font-weight: 600;
    letter-spacing: 0em;
    color: var(--textColor);
    margin: 0px 0 20px 0;
    padding: 0;
    text-align: center;
    font-size: 24px;
    line-height: 30px;
    @media (--viewportMedium) {
      font-size: 28px;
      line-height: 38px;
    }
    @media (--viewportLarge) {
      font-size: 30px;
      line-height: 43px;
    }
  }
  & p {
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0em;
    color: var(--textColor);
    margin: 0px;
    padding: 0;
    text-align: center;
  }
  & .healingPractitioner {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 40px;
    @media (--viewportSmall) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (--viewportMedium) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (--viewportLarge) {
      grid-row-gap: 50px;
      grid-template-columns: repeat(4, 1fr);
    }
    & .category {
      text-align: left;
      & h4 {
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: 0em;
        color: var(--textColor);
        margin: 0 0 3px 0;
        padding: 0;
      }
      & p {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: var(--textColor);
        margin: 0 0 3px 0;
        padding: 0;
      }
      & .findSessions {
        font-size: 16px;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: 0em;
        color: var(--textColor);
        text-decoration: underline;
        transition: var(--transitionStyle);
        &:hover {
          color: var(--marketplaceColor);
          transition: var(--transitionStyle);
        }
      }
    }
  }
}

.reviewsContainer {
  display: flex;
  background-color: #e5f0fd;
  & .contentWidth {
    display: flex;
    flex-direction: column;
    @media (--viewportLarge) {
      flex-direction: row;
    }
    & .reviewsDescription {
      width: 100%;
      @media (--viewportLarge) {
        width: 50%;
        padding-right: 10%;
      }
      & h1 {
        font-weight: 600;
        letter-spacing: 0em;
        color: var(--textColor);
        margin: 0px 0 20px 0;
        padding: 0;
        font-size: 24px;
        line-height: 30px;
        @media (--viewportMedium) {
          font-size: 28px;
          line-height: 38px;
        }
        @media (--viewportLarge) {
          font-size: 30px;
          line-height: 43px;
        }
      }
      & p {
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0em;
        color: var(--textColor);
        margin: 0px;
        padding: 0;
      }
      & .ratingSec {
        margin-top: 30px;
        display: flex;
        @media (--viewportLarge) {
          margin-top: 50px;
        }
        & > * {
          width: calc(100% / 2);
          & h1 {
            margin: 0;
            padding: 0;
          }
          & .starRating {
            display: flex;
            align-items: center;
            margin-top: 10px;
            & > svg {
              width: 10px;
              height: 10px;
              &:not(:last-child) {
                margin-right: 7px;
              }
              & > path {
                fill: #2e95bf;
              }
            }
          }
        }
      }
    }
    & .reviewsSlider {
      width: 100%;
      margin-top: 20px;
      padding-top: 20px;
      border-top: solid 1px var(--colorGrey200);
      @media (--viewportLarge) {
        width: 50%;
        border: none;
        padding-top: 0px;
        margin: 0;
      }
      & .sliderItem {
        & .userBlock {
          display: flex;
          align-items: center;
          margin-bottom: 30px;
          & .userImg {
            width: 80px;
            height: 80px;
            border-radius: 100px;
            background-color: var(--colorGrey100);
            & > img {
              max-width: 100%;
              max-height: 100%;
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }
          & .userInfo {
            padding-left: 30px;
            width: calc(100% - 80px);
            & h4 {
              font-size: 16px;
              font-weight: 500;
              line-height: 30px;
              letter-spacing: 0em;
              color: var(--textColor);
              margin: 0;
              padding: 0;
            }
            & p {
              font-size: 15px;
              font-weight: 400;
              line-height: 26px;
              letter-spacing: 0.02em;
              color: var(--textColor);
              margin: 0;
              padding: 0;
            }
          }
        }
        & .reviewsDescription {
          font-size: 18px;
          font-weight: 500;
          line-height: 30px;
          width: 100%;
          letter-spacing: 0em;
          color: var(--textColor);
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}
.contentWidth {
  position: relative;
  z-index: 1;
  max-width: 1290px;
  width: 100%;
  margin: 0px auto;
  padding: 40px 24px;
  @media (--viewportLarge) {
    padding: 80px 24px;
  }
}
.moreAbout {
  & .contentWidth {
    display: flex;
    flex-direction: column;
    @media (--viewportMedium) {
      flex-direction: row;
    }
    & .moreInfoBlock {
      text-align: center;
      width: 100%;
      @media (--viewportMedium) {
        width: calc(100% / 3);
        padding: 0 26px;
      }
      @media (--viewportLarge) {
        padding: 0 46px;
      }
      &:not(:last-child) {
        margin-bottom: 30px;
        @media (--viewportMedium) {
          margin-bottom: 0px;
        }
      }
      & > svg {
        width: 50px;
        height: 50px;
        & path {
          fill: var(--marketplaceColor);
        }
      }
      & h4 {
        font-size: 20px;
        font-weight: var(--fontWeightSemiBold);
        line-height: 30px;
        letter-spacing: 0em;
        color: var(--colorWhite);
        margin: 30px 0 10px 0;
        padding: 0;
      }
      & p {
        font-size: 17px;
        font-weight: var(--fontWeightRegular);
        line-height: 26px;
        letter-spacing: 0.02em;
        color: var(--colorWhite);
        margin: 0;
        padding: 0;
      }
    }
  }
}
.sliderNav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 300px;
  width: 100%;
  margin-top: 50px;
  & > span {
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    color: var(--textColor);
  }
  & .sliderProgress {
    width: calc(100% - 30px);
    background-color: #dddddd;
    height: 2px;
    position: relative;
    z-index: 0;
    margin: 0 20px;
    & .completedStep {
      background-color: var(--textColor);
      height: 100%;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      z-index: 1;
    }
  }
}
.newsletterSec {
  background-color: #087acb;
  & .contentWidth {
    display: flex;
    justify-content: space-between;
    padding: 40px 24px;
    flex-direction: column;
    @media (--viewportLarge) {
      padding: 65px 24px;
      flex-direction: row;
    }
    & .leftSec {
      display: flex;
      width: 100%;
      justify-content: center;
      margin-bottom: 20px;
      @media (--viewportLarge) {
        width: 50%;
        margin-bottom: 0px;
        text-align: left;
      }
      & .newsletterImg {
        & > img {
          max-width: 100%;
        }
      }
      & .newsletterInfo {
        padding-left: 20px;

        @media (--viewportLarge) {
          padding-right: 20px;
        }
        & h2 {
          font-size: 18px;
          line-height: 23px;
          font-weight: 600;
          letter-spacing: 0em;
          color: var(--colorWhite);
          margin: 0 0 5px 0;
          padding: 0;
          @media (--viewportSmall) {
            font-size: 20px;
            line-height: 30px;
          }
          @media (--viewportMedium) {
            font-size: 26px;
            line-height: 38px;
          }
        }
        & p {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          color: var(--colorWhite);
          margin: 0;
          padding: 0;
          @media (--viewportSmall) {
            font-size: 16px;
            line-height: 30px;
          }
        }
      }
    }
    & .rightSec {
      display: flex;
      width: 100%;
      justify-content: center;
      flex-direction: column;
      @media (--viewportSmall) {
        flex-direction: row;
      }
      @media (--viewportLarge) {
        width: 50%;
        text-align: left;
      }
      & .formRow {
        height: 50px;
        @media (--viewportLarge) {
          height: 60px;
        }
        &:not(:last-child) {
          margin-bottom: 10px;
          @media (--viewportSmall) {
            margin-right: 17px;
            margin-bottom: 0px;
          }
        }
        &:nth-child(2) {
          min-width: 40%;
        }
        & input {
          font-size: 15px;
          font-weight: 400;
          line-height: 26px;
          letter-spacing: 0.02em;
          color: var(--textColor);
          border-radius: 4px;
          background-color: var(--colorWhite);
          padding: 0 26px;
          height: 100%;

          &::placeholder {
            color: var(--textColor);
            opacity: 1;
          }
        }
        & button {
          background-color: #0635c9;
          color: var(--colorWhite);
          font-size: 15px;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: 0em;
          padding: 10px 25px;
          border: none;
          border-radius: 4px;
          transition: var(--transitionStyle);
          height: 100%;
          width: 100%;
          @media (--viewportSmall) {
            width: auto;
          }
          @media (--viewportLarge) {
            padding: 20px 35px;
          }
          &:hover {
            transition: var(--transitionStyle);
            color: var(--colorWhite);
            background-color: var(--marketplaceColor);
            cursor: pointer;
          }
        }
      }
    }
  }
}
.popularMoorage {
  width: 100%;
  display: block;
  background-color: var(--colorWhite);
  padding: 50px 0;
  & .contentWidth {
    padding: 0 24px;
    display: block;
  }
  & .card {
    position: relative;
    padding-bottom: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 6px;
    overflow: hidden;
    & .cardDetails {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
      padding: 20px;
      & > h3 {
        font-size: 20px;
        font-weight: 600;
        line-height: 38px;
        letter-spacing: 0em;
        color: var(--colorWhite);
        text-align: center;
        padding: 0;
        margin: 0;
      }
      & .button {
        width: 100%;
        margin-top: 10px;
        transition: var(--transitionStyle);
      }
    }
  }
}

:global(.slick-slider) {
  width: 100%;
}
:global(.slick-list) {
  margin: 0 -10px;
}
:global(.slick-track) {
  margin: 0;
}
:global(.slick-slide) {
  padding: 0px 10px;
  & > div {
    display: flex;
  }
}
:global(.slick-dots) {
  bottom: -40px;
  & > li {
    margin: 0;
    & > button {
      padding: 0;
      &::before {
        font-size: 8px;
      }
    }
  }
}
:global(.slick-arrow) {
  width: 30px;
  height: 30px;
  display: inline-flex;
  background-color: var(--colorWhite);
  align-items: center;
  justify-content: center;
  box-shadow: var(--boxShadowPopupLight);
  border-radius: 100px;
  z-index: 1;
  &:hover,
  &:focus {
    background-color: var(--marketplaceColor);
  }
}
:global(.slick-arrow.slick-prev) {
  left: -10px;
  @media (--viewportLarge) {
    left: -16px;
  }
}
:global(.slick-arrow.slick-next) {
  right: -10px;
  @media (--viewportLarge) {
    right: -16px;
  }
}
:global(.slick-arrow.slick-prev.slick-disabled) {
  background-color: hsl(0deg 0% 65.4%);
}
:global(.slick-arrow.slick-next.slick-disabled) {
  background-color: hsl(0deg 0% 65.4%);
}
:global(.slick-arrow.slick-prev.slick-disabled:hover::before) {
  filter: invert(0);
}
:global(.slick-arrow.slick-next.slick-disabled:hover::before) {
  filter: invert(0);
}
:global(.slick-arrow.slick-prev.slick-disabled:active::before) {
  filter: invert(0);
}
:global(.slick-arrow.slick-next.slick-disabled:active::before) {
  filter: invert(0);
}
:global(.slick-arrow.slick-prev.slick-disabled:focus::before) {
  filter: none;
}
:global(.slick-arrow.slick-next.slick-disabled:focus::before) {
  filter: none;
}

:global(.slick-arrow.slick-prev:hover::before) {
  filter: invert(1);
}
:global(.slick-arrow.slick-next:hover::before) {
  filter: invert(1);
}
:global(.slick-arrow.slick-prev:active::before) {
  filter: invert(1);
}
:global(.slick-arrow.slick-next:active::before) {
  filter: invert(1);
}
:global(.slick-arrow.slick-prev:focus::before) {
  filter: invert(1);
}
:global(.slick-arrow.slick-next:focus::before) {
  filter: invert(1);
}
/* :global(.slick-arrow.slick-prev::before) {
  content: ' ';
  display: inline-block;
  width: 7px;
  height: 11px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSIxMSIgdmlld0JveD0iMCAwIDcgMTEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjY1NDk2OCA1LjgwMTg2TDUuNzQyNzkgMTAuODc1NkM1LjkwOTQ5IDExLjA0MTcgNi4xNzkzOCAxMS4wNDE1IDYuMzQ1NzkgMTAuODc0N0M2LjUxMjA4IDEwLjcwODEgNi41MTE2NSAxMC40MzggNi4zNDQ5MyAxMC4yNzE3TDEuNTU5OTUgNS40OTk5OEw2LjM0NTExIDAuNzI4MjQyQzYuNTExOCAwLjU2MTk1NCA2LjUxMjIzIDAuMjkyMDg5IDYuMzQ1OTcgMC4xMjUzNzFDNi4yNjI1NCAwLjA0MTc5NzYgNi4xNTMyNSA5LjUwOTAyZS0wNiA2LjA0Mzk2IDkuNDk5NDZlLTA2QzUuOTM0OTUgOS40ODk5M2UtMDYgNS44MjYwOSAwLjA0MTUxODIgNS43NDI4MiAwLjEyNDUxMkwwLjY1NDk2OCA1LjE5ODEzQzAuNTc0NjgxIDUuMjc4MDEgMC41Mjk2MjggNS4zODY3MiAwLjUyOTYyOCA1LjQ5OTk4QzAuNTI5NjI4IDUuNjEzMjUgMC41NzQ4MSA1LjcyMTgzIDAuNjU0OTY4IDUuODAxODZaIiBmaWxsPSIjMDUxMDM2Ii8+Cjwvc3ZnPgo=);
}
:global(.slick-arrow.slick-next::before) {
  content: ' ';
  display: inline-block;
  width: 7px;
  height: 11px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSIxMSIgdmlld0JveD0iMCAwIDcgMTEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik02LjM0NTA5IDUuMTk4MTRMMS4yNTcyNyAwLjEyNDM5MkMxLjA5MDU3IC0wLjA0MTc0NjMgMC44MjA2ODUgLTAuMDQxNDY3IDAuNjU0MjY3IDAuMTI1MjUxQzAuNDg3OTc5IDAuMjkxOTQ4IDAuNDg4NDA4IDAuNTYxOTgzIDAuNjU1MTI3IDAuNzI4MjVMNS40NDAxMSA1LjUwMDAyTDAuNjU0OTU1IDEwLjI3MThDMC40ODgyNTggMTAuNDM4IDAuNDg3ODI4IDEwLjcwNzkgMC42NTQwOTUgMTAuODc0NkMwLjczNzUxOSAxMC45NTgyIDAuODQ2ODEgMTEgMC45NTYxIDExQzEuMDY1MTEgMTEgMS4xNzM5NyAxMC45NTg1IDEuMjU3MjUgMTAuODc1NUw2LjM0NTA5IDUuODAxODdDNi40MjUzOCA1LjcyMTk5IDYuNDcwNDMgNS42MTMyOCA2LjQ3MDQzIDUuNTAwMDJDNi40NzA0MyA1LjM4Njc1IDYuNDI1MjUgNS4yNzgxNyA2LjM0NTA5IDUuMTk4MTRaIiBmaWxsPSIjMDUxMDM2Ii8+Cjwvc3ZnPgo=);
} */
.sectionHead {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  flex-direction: column;
  @media (--viewportSmall) {
    flex-direction: row;
    align-items: flex-end;
  }
  & .leftSec {
    margin-bottom: 20px;
    text-align: center;
    @media (--viewportSmall) {
      margin-bottom: 0;
      text-align: left;
    }
    & h2 {
      font-weight: 600;
      letter-spacing: 0em;
      color: var(--colorBlack);
      margin: 0px 0 5px 0;
      padding: 0;
      font-size: 20px;
      line-height: 130%;
      @media (--viewportMedium) {
        font-size: 24px;
      }
      @media (--viewportLarge) {
        font-size: 26px;
      }
    }
    & p {
      font-size: 16px;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0em;
      color: var(--textColor);
      margin: 0px;
      padding: 0;
    }
  }
  & .rightSec {
    text-align: center;
    @media (--viewportSmall) {
      text-align: initial;
    }
    & > a {
      display: inline-block;
      color: var(--marketplaceColor);
      text-decoration: underline;
      transition: var(--transitionStyle);
      &:hover {
        transition: var(--transitionStyle);
        color: var(--marketplaceColorDark);
        & > svg {
          & > path {
            stroke: var(--colorWhite);
          }
        }
      }
      & > svg {
        margin-left: 12px;
        fill: none;
      }
    }
  }
}
.button {
  composes: whiteBtn from global;
}
.listingCard {
  width: 100%;
}
.interestedSection {
  background-color: var(--colorWhite);
  padding: 0 24px;

  & .bannerContent {
    max-width: var(--contentMaxWidth);
    width: 100%;
    background-color: var(--colorWhite);
    border-radius: 40px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    padding: 20px 20px 25px;
    position: relative;
    /* z-index: 1; */
    margin: 24px auto 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    @media (--viewportMedium) {
      flex-direction: row;
      margin: -80px auto 0;
    }
    & .bannerLeft {
      width: 100%;
      margin-bottom: 14px;
      @media (--viewportMedium) {
        margin-bottom: 0;
      }
      @media (--viewportLarge) {
        width: 70%;
      }
      & > h2 {
        color: var(--marketplaceColor);
        font-size: 20px;
        font-weight: var(--fontWeightMedium);
        line-height: 130%;
        padding: 0;
        margin: 0 0 10px 0;
        @media (--viewportMedium) {
          font-size: 24px;
        }
      }
      & > p {
        font-size: 14px;
        line-height: 140%;
        color: var(--colorDarkBlue);
        margin: 0;
        padding: 0;
        @media (--viewportMedium) {
          font-size: 16px;
        }
      }
    }
    & > a {
      color: var(--marketplaceColor);
      font-weight: var(--fontWeightRegular);
      font-size: 16px;
      line-height: 120%;
      border-radius: 6px;
      text-align: center;
      display: inline-flex;
      flex-wrap: nowrap;
      text-decoration: underline;
      white-space: nowrap;
      transition: var(--transitionStyle);
      @media (--viewportMedium) {
        font-size: 18px;
      }
      @media (--viewportLarge) {
        font-size: 20px;
      }
      &:hover {
        color: var(--marketplaceColorDark);
      }
    }
  }
}

.testimonialSection {
  background-color: var(--colorGrey50);
  & > .contentWidth {
    padding: 50px 24px;

    & .contentWidth {
      padding: 0px;
      & :global(.slick-slider) {
        width: 100%;
      }
      & :global(.slick-track) {
        display: flex;
      }
      & :global(.slick-slide) {
        padding: 0px 10px;
        height: auto;
        & > div {
          display: flex;
          height: 100%;
        }
      }
      & .testimonialCard {
        background-color: var(--colorWhite);
        /* background-image: linear-gradient(120deg, #fff 20%, #f5f5f5 70%); */
        padding: 30px;
        border-radius: 10px;
        border: solid 1px var(--colorGrey200);
        display: inline-flex !important;
        flex-direction: column;
        justify-content: space-between;
        & > p {
          margin: 0;
          font-size: 16px;
          font-weight: var(--fontWeightRegular);
          line-height: 150%;
          color: var(--colorGrey800);
          padding: 0;
        }
        & .userinfo {
          padding-top: 20px;
          border-top: solid 1px var(--colorGrey100);
          margin-top: 20px;
          color: var(--colorBlack);
          text-transform: uppercase;
          font-size: 14px;
          font-weight: var(--fontWeightBold);
        }
      }
    }
  }
}

.destinationSection {
  & .contentWidth {
    position: relative;
    z-index: 0;
    & .destinationBlock {
      display: flex;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      & .moreInfoBlock {
        border-radius: 16px;
        overflow: hidden;
        position: relative;
        transition: var(--transitionStyleLarge);
        width: calc(100% / 1.3);
        min-width: calc(100% / 1.2);
        height: 350px;
        scroll-snap-align: center;
        &:not(:last-child) {
          margin-right: 20px;
          @media (--viewportMedium) {
            margin-right: 24px;
          }
        }
        @media (--viewportSmall) {
          min-width: calc(100% / 2);
          width: calc(100% / 2);
        }
        @media (--viewportMedium) {
          min-width: calc(100% / 3);
          width: calc(100% / 3);
        }
        @media (--viewportLarge) {
          min-width: auto;
          width: calc(100% / 4);
        }
        &:hover {
          transition: var(--transitionStyleLarge);
          cursor: pointer;
          & > img {
            transition: var(--transitionStyleLarge);
            transform: scale(1.05);
          }
        }

        & > img {
          max-width: 100%;
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: var(--transitionStyleLarge);
          transform: scale(1);
        }
        & .destinationContent {
          padding: 24px;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          background-image: linear-gradient(181deg, transparent, rgb(0 0 0 / 80%));
          & > h4 {
            margin: 0;
            font-size: 20px;
            line-height: 130%;
            color: var(--colorWhite);
            font-weight: var(--fontWeightBold);
            padding: 0;
          }
          & > p {
            font-size: 16px;
            line-height: 140%;
            font-weight: var(--fontWeightRegular);
            color: var(--colorWhite);
            margin: 10px 0 0 0;
            padding: 0;
          }
        }
      }
    }
  }
}
.makeDifferentSection {
  text-align: center;
  padding: 50px 24px;
  & .contentWidth {
    padding: 0;
    & h1 {
      font-weight: var(--fontWeightBold);
      letter-spacing: 0em;
      color: var(--colorDarkBlue);
      margin: 0px 0 5px 0;
      padding: 0;
      font-size: 20px;
      line-height: 130%;
      @media (--viewportMedium) {
        font-size: 24px;
      }
      @media (--viewportLarge) {
        font-size: 26px;
      }
    }
    & .makeDifferentContent {
      margin-top: 32px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 24px;
      @media (--viewportMedium) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media (--viewportLargeWithPaddings) {
        grid-template-columns: repeat(6, 1fr);
      }
      & .makeDifferentBlock {
        display: flex;
        flex-direction: column;
        & .makeDifferentImg {
          margin-bottom: 24px;
          height: 50px;
          @media (--viewportSmall) {
            height: 60px;
          }
          @media (--viewportMedium) {
            height: 70px;
          }
          & > img {
            max-width: 100%;
            width: 50px;
            @media (--viewportSmall) {
              width: 60px;
            }
            @media (--viewportMedium) {
              width: 70px;
            }
          }
        }
        & > h2 {
          font-weight: var(--fontWeightSemiBold);
          letter-spacing: 0em;
          color: var(--colorDarkBlue);
          margin: 0px 0 10px 0;
          padding: 0;
          font-size: 15px;
          line-height: 110%;
          @media (--viewportMedium) {
            font-size: 17px;
          }
        }
        & > p {
          font-weight: var(--fontWeightRegular);
          letter-spacing: 0em;
          color: var(--colorDarkBlue);
          margin: 0px;
          padding: 0;
          font-size: 14px;
          line-height: 110%;
          @media (--viewportMedium) {
            font-size: 16px;
          }
        }
      }
    }
  }
}
